import m from 'mithril'
import { jsonDateParser } from "json-date-parser"
import jwt from 'jwt-decode'
import lru from 'lru-cache'
import u from 'umbrellajs'
import utils from './utils'

var commonWait = 10 * 60
var longWait = 60 * 60
var cache = new lru(500)

function extract(xhr) {
  return JSON.parse(xhr.responseText, jsonDateParser)
}

window.mvt_page = function (a) {
  let url = a.getAttribute('href')
  a.parentNode.removeChild(a)
  this.load(api.get(url, api.token))
}

window.mvt_expand = function(a) {
  let level = u(a.parentNode)
  if (level.hasClass('show')) {
    level.removeClass('show')
  } else {
    let m = a.parentNode.className.match(/mv_lvl(\d+)/)
    u("." + m[0]).removeClass('show')
    level.addClass('show')
  }
}

var api = {
  fetch(url) {
    return fetch(process.env.MV_API + url,
      {redirect: "manual", headers: {"Authorization": "Bearer " + this.token}})
  },

  fetch2(url, opts) {
    return new Promise((resolve, reject) =>
      fetch(url, opts).
        then(resp => resp.text().then(txt => {
          let obj = {}
          if (txt) {
            obj = JSON.parse(txt, jsonDateParser)
          }
          if (resp.ok || resp.status === 304) {
            resolve(obj)
          } else if (resp.status === 401) {
            m.route.set("/signin")
          } else {
            reject(obj)
          }
          m.redraw()
        })))
  },

  request(method, path, body = null, token = null) {
    let headers = {}
    if (body && !(body instanceof FormData)) {
      headers = {"Content-Type": "application/json",
        "Accept": "application/json"}
      body = JSON.stringify(body)
    }
    if (token) {
      headers["Authorization"] = "Bearer " + token
    }
    return this.fetch2(process.env.MV_API + path,
      {method, headers, body})
  },

  post(path, body = null, token = null) {
    return this.request("POST", path, body, token)
  },

  put(path, body = null, token = null) {
    return this.request("PUT", path, body, token)
  },

  get(path, token = null, expires = null) {
    let id = `GET ${path}`
    if (expires) {
      let obj = cache.get(id)
      expires *= 1000
      if (obj) {
        return Promise.resolve(obj).
          finally(() => m.redraw())
      }
    }
    let req = this.request("GET", path, null, token)
    if (expires) {
      req.then(obj => cache.set(id, obj, expires))
    }
    return req
  },

  delete(path, token = null) {
    return this.request("DELETE", path, null, token)
  },

  del(...keys) {
    keys.map(k => cache.del(k))
  },

  // form submission convenience
  // pass in the form or the submit event
  form(ev, method, path, token = null) {
    let body
    let form = ev
    if (ev.preventDefault) {
      ev.preventDefault()
      form = ev.target
    }
    if (form instanceof HTMLFormElement) {
      body = new FormData(form)
    } else {
      body = new FormData()
      for (let key in form) {
        body.append(key, form[key])
      }
    }
    let headers = {}
    if (token) {
      headers["Authorization"] = "Bearer " + token
    }
    return this.fetch2(process.env.MV_API + path,
      {method, headers, body})
  },

  // Specific calls used again and again
  getGroups() {
    return this.get("groups", this.token, longWait)
  },

  getGroupLatest(url) {
    return this.get("groups/latest/" + url, this.token)
  },

  getFrames() {
    return this.get("frames", this.token, commonWait)
  },

  getFrame(frameId) {
    return this.get("frames/id/" + frameId, this.token, commonWait)
  },

  saveFrame(frameId, definition) {
    let method = "POST"
    let url = "frames"
    if (frameId) {
      method = "PUT"
      url = "frames/id/" + frameId
    }
    return this.request(method, url, {definition}, this.token).
      then(() => this.del("GET frames", "GET " + url))
  },

  // Posts
  savePost(postId, obj) {
    let method = "POST"
    let url = "posts"
    if (postId) {
      method = "PUT"
      url = "posts/id/" + postId
    }
    return this.request(method, url, obj, this.token).
      then(p => this.expirePost(p))
  },

  publishPost(postId, obj) {
    return this.put("posts/publish/" + postId, obj, this.token).
      then(p => this.expirePost(p))
  },

  highlightPost(postId) {
    return this.post("posts/crosspost/" + postId, {target: "highlights", state: 1}, this.token)
  },

  getPosts() {
    return this.get("posts", this.token, commonWait)
  },

  deletePost(postId) {
    return this.delete("posts/id/" + postId, this.token).
      then(p => this.expirePost(p)) 
  },

  expirePost(post) {
    let id = `posts/u/${this.profile.username}/u/${post.stub}`
    this.del("GET posts", "GET " + id)
    return post
  },

  async postContentAndFrame(post) {
    let url = `/user/${post.author.username}/posts/${post.stub}/index.json`
    let promises = [], frame
    promises.push(fetch(url, {cache: 'no-cache'}).then(res => res.json()).
      then(obj => post.content = obj))
    url = `/user/${post.author.username}/mv_f${post.frame_id}.json`
    promises.push(fetch(url, {cache: 'no-cache'}).then(res => res.json()).
      then(obj => frame = obj))
    post.author = this.author(post.author)
    await Promise.all(promises)
    return frame
  },

  // Profile
  setToken(token, save = false) {
    if (token) {
      try {
        this.token = token
        this.profile = jwt(token)
        if (save) {
          localStorage.setItem('token', token)
        }
      } catch (e) {}
      return token
    }
  },

  saveProfile(ev) {
    return this.form(ev, "PUT", "profile", this.token).
      then(obj => this.setToken(obj.token, true))
  },

  unsubscribe(uuid) {
    return this.post(`email/${uuid}/unsubscribe`)
  },

  avatar(profile = this.profile) {
    if (profile?.avatar) {
      return profile.avatar.startsWith('.') ? `/user/${profile.username}/avatar${profile.avatar}` :
        profile.avatar
    }
  },

  nametag(profile = this.profile) {
    return profile?.nametag || profile?.username || ''
  },

  author(profile = this.profile) {
    return {username: profile.username,
      nametag: this.nametag(profile), avatar: this.avatar(profile)}
  },

  // Themes
  setTheme(name, save = false) {
    if (name) {
      this.theme = name
      document.body.className = "mvt_" + name
      if (save) {
        localStorage.setItem('theme', name)
      }
    }
  },

  //
  // Intercept links within the app - to prevent hitting the server,
  // just handle them in JS
  //
  routeLinks() {
    let routes = u("a.route").each(a => {
      let au = u(a)
      a.getAttribute('href') === window.location.pathname ? au.addClass('active') : au.removeClass('active')
      au.removeClass('route') 
    })
    if (m.route.prefix === "") {
      routes.handle('click', e => m.route.set(e.currentTarget.href))
    }
  },

  updateTimes() {
    u("time.dt-published").each(time => {
      try {
        let dt = new Date(time.dateTime)
        time.innerText = utils.timeBlur(dt)
      } catch {}
    })
  },

  title(title = null) {
    window.title = title ? "Multiverse &#xbb; " + title : "Multiverse"
    let trail = document.getElementById("trail")
    if (trail) trail.innerText = title ? title : " "
    this.routeLinks()
    this.updateTimes()

    //
    // Google Analytics events
    //
    if (window.gtag) {
      window.gtag('send', 'page', m.route.get())
      window.gtag('send', 'pageview')
    }
  },

  error(state) {
    m.route.set("/error", null, {state})
  },

  ready(fn = null) {
    document.addEventListener("DOMContentLoaded", () => {
      api.setTheme(localStorage.getItem('theme'))
      //
      // Theme switcher
      //
      u("a.theme-c").on('click', e => {
        let name = e.currentTarget.id.substr(6)
        this.setTheme(name, true)
      })

      if (fn) {
        fn()
      }
    })
  }
}

api.setToken(localStorage.getItem('token'))

export default api
