import u from 'umbrellajs'
import api from './api'

api.ready(() => {
  u('form').on('submit', e => {
    e.preventDefault()
    e.target.disabled = true
    api.form(e.target, 'POST', 'signup').
      then(() => window.location = "/profile/signin").
      catch(ex => {
        e.target.disabled = false
        u('.signup .error').html(`<p>${ex.details}</p>`)
      })
  })

  u('.smolview a').each(ele => {
    u(ele).on('click', ev => {
      ev.preventDefault()
      let url = new URL(ele.href)
      document.getElementById('iframe').src = url.origin + '/embed' +
        url.pathname + '?width=440&height=688'
      u('ul.opts > li').removeClass('sel')
      u(ele).closest('li').addClass('sel')
    })
  })
})
