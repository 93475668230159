import m from 'mithril'
import { jsonDateParser } from "json-date-parser"

//
// Deep copy an object.
//
function dup (obj) {
  return JSON.parse(JSON.stringify(obj), jsonDateParser)
}

//
// Measure a Mithril element's size when it's rendered
//
function measure(ele) {
  return new Promise(resolve => {
    let div = document.createElement("div")
    if (ele.tag === 'img') {
      ele.attrs.onload = e => {
        resolve([e.target.clientWidth, e.target.clientHeight, e.target])
        document.body.removeChild(div)
      }
    }
    div.style.visibility = "hidden"
    div.style.display = "inline-block"
    div.style.fontSize = "16px"
    div.style.whiteSpace = "nowrap"
    m.render(div, ele)
    document.body.appendChild(div)
    if (ele.tag !== 'img') {
      resolve([div.clientWidth, div.clientHeight])
      document.body.removeChild(div)
    }
  })
}

//
// Get page coordinates for an element
//
function pos (ele, base = document.body) {
  let rect = ele.getBoundingClientRect(), page = base.getBoundingClientRect()
  return {top: rect.top - page.top, left: rect.left - page.left, width: rect.width, height: rect.height,
    right: page.width - rect.width}
}

function randomIn(items) {
  return items[Math.floor(Math.random() * items.length)]
}

function randId() {
  return Math.random().toString(36).substr(2)
}

const id = (() => {
  let currentId = 0
  const map = new WeakMap()

  return (object) => {
    if (!map.has(object)) {
      map.set(object, ++currentId)
    }

    return map.get(object)
  }
})()

function timeBlur(at) {
  let today = new Date()
    , yesterday = new Date(today - (24 * 60 * 60 * 1000))
    , month = at.getMonth()
    , year = at.getFullYear()
    // , firstWeekday = new Date(year, month, 1).getDay()
    , lastDateOfMonth = new Date(year, month + 1, 0).getDate()
    , offsetDate = at.getDate() - 1 // + firstWeekday - 1 (for first day of the week)
    , index = 1 // start index at 0 or 1, your choice
    // , weeksInMonth = index + Math.ceil((lastDateOfMonth + firstWeekday - 7) / 7)
    , week = index + Math.floor(offsetDate / 7)

  let weekn, yearn = ""
  if (month === today.getMonth() && year === today.getFullYear() && at.getDate() === today.getDate()) {
    return 'today'
  } else if (month === yesterday.getMonth() && year === yesterday.getFullYear() && at.getDate() === yesterday.getDate()) {
    return 'yesterday'
  }

  if (today - at > 31536000000) {
    yearn = " " + year
  }
  if (week === 1) {
    weekn = "1st"
  } else if (week === 2) {
    weekn = "2nd"
  } else if (week === 3) {
    weekn = "3rd"
  } else {
    weekn = `${week}th`
  }
  return `the ${weekn} week of ${at.toLocaleString('default', { month: 'long' })}${yearn}`
}

function download(options) {
	var url = options.url || ("data:" + options.mimeType + ";charset=UTF-8," + encodeURIComponent(options.contents))
	var link = document.createElement('a')
	link.setAttribute('download', options.filename)
	link.setAttribute('href', url)
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}

function copyLink() {
  if (navigator.share) {
    navigator.share({url: window.location.href})
  } else if (navigator.clipboard) {
    navigator.clipboard.writeText(window.location.href)
  }
}

export default {dup, id, measure, randId, randomIn, pos, timeBlur, download, copyLink}
